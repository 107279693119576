/* Quiz.css */
.quiz {
    text-align: left;
    width: 100%;
    max-width: 600px;
  }
  
  .quiz-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .quiz-options {
    list-style: none;
    padding: 0;
    width: 100%;
  }
  
  .quiz-option {
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f7f7f7;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .quiz-option:hover {
    background-color: #eaeaea;
    border-color: #ccc;
  }
  
  .quiz-option.selected {
    background-color: #4CAF50;
    color: white;
    border-color: #4CAF50;
  }
  
  button {
    margin-top: 1rem;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #45A049;
  }
  
  @media (max-width: 768px) {
    .quiz {
      width: 100%;
    }
  }
  

