/* SummaryScreen.css */
.summary-screen {
    text-align: center;
    padding: 2rem;
  }
  
  .quiz-summary-item {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  .quiz-summary-item.correct {
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .quiz-summary-item.incorrect {
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .quiz-summary-item p {
    margin: 0.5rem 0;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: 700;
  }
  
  p {
    font-size: 1rem;
    margin: 0.5rem 0;
  }
  